/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import { Crisp } from 'crisp-sdk-web';
import { Banner } from 'elements/Banner/Banner';
import Button from 'elements/Button';
import { VistoCheckCircle } from 'elements/Icons/Icons';
import Text from 'elements/Text';
import { get } from 'lodash';
import Link from 'next/link';
import { FC, useEffect } from 'react';
import { Lock } from 'react-feather';
import { PLAN_DISPLAY_NAME } from 'static/Payments.static';

export enum UpgradeAccountNoticeType {
  APPLICATIONS,
  REPRESENTATIVES,
  TEMPLATES,
}

export enum FeatureBlockedType {
  LEADS = 'LEADS',
  CUSTOMIZE_LOGO = 'CUSTOMIZE_LOGO',
  CUSTOMIZE_EMAIL = 'CUSTOMIZE_EMAIL',
  CUSTOMIZE_NOTIFICATIONS = 'CUSTOMIZE_NOTIFICATIONS',
  CUSTOMIZE_WELCOME = 'CUSTOMIZE_WELCOME',
  MFA = 'MFA',
  FORMS = 'FORMS',
  TASKS = 'TASKS',
  CHAT = 'CHAT',
  CHAT_TRIAL = 'CHAT_TRIAL',
  RETAINER = 'RETAINER',
  USERS = 'USERS',
  DRAFT = 'DRAFT',
}

export enum FeatureNoPermissionBlockedType {
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  CREATE_DRAFT_TEMPLATE = 'CREATE_DRAFT_TEMPLATE',
  EXTRACT_TEXT_DOCUMENTS = 'EXTRACT_TEXT_DOCUMENTS',
}

export const UpgradeAccountNotice: FC<{
  type: UpgradeAccountNoticeType;
}> = ({ type }) => {
  const CONTENT = {
    [UpgradeAccountNoticeType.APPLICATIONS]: {
      title: 'Creating Applications Disabled',
      message: 'Upgrade to a paid plan to create additional applications',
    },
    [UpgradeAccountNoticeType.REPRESENTATIVES]: {
      title: 'Creating Representatives Disabled',
      message:
        'You have used up all of your representatives for your current plan. To create a new representative, please upgrade your Visto account.',
    },
    [UpgradeAccountNoticeType.TEMPLATES]: {
      title: 'Creating Templates Disabled',
      message:
        'You have used up all of your custom application templates for your current plan. To create a new template, please upgrade your Visto account.',
    },
  };

  const content = get(CONTENT, type, null);

  if (!content) {
    return null;
  }

  return (
    <>
      <div className="bg-red-600 rounded-full w-28 h-28 flex justify-center items-center p-4 mx-auto mb-8">
        <img
          className="w-[70px]"
          src={'/hand-raised-white.png'}
          alt="Confirm"
        />
      </div>
      <div className="flex justify-center mb-2">
        <Banner variation="error" className="text-center">
          Account upgrade required
        </Banner>
      </div>
      <Text.Heading
        variant="none"
        className="fs24 text-center font-body font-bold mb-2"
      >
        {content.title}
      </Text.Heading>
      <Text.Paragraph className={`text-center mb-3`}>
        {content.message}
      </Text.Paragraph>
      <Button className="mx-auto mt-8" link="/account/billing" size="large">
        Upgrade Account
      </Button>
      <Button className="mx-auto" link="/account/billing" format="basic">
        View Usage Metrics
      </Button>
    </>
  );
};

export const FeaturedBlockedNotice: FC<{
  type: FeatureBlockedType;
  isOverlay?: boolean;
  hideOverflow?: boolean;
  className?: string;
}> = ({ type, isOverlay = false, hideOverflow = false, className }) => {
  useEffect(() => {
    if (!hideOverflow) {
      return;
    }

    const mainContent = document.getElementById('mainContentArea');

    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (!isOverlay || !hideOverflow) {
      return;
    }

    const mainContent = document.getElementById('mainContentArea');

    if (!mainContent) {
      return;
    }

    mainContent.style.overflow = 'hidden';

    return () => {
      mainContent.style.overflow = '';
    };
  }, []);

  const CONTENT: {
    [key in FeatureBlockedType]?: {
      title: string;
      message: string;
      tips?: string[];
      allowedPlans?: string[];
    };
  } = {
    [FeatureBlockedType.LEADS]: {
      title: 'Upgrade to Access Leads',
      message:
        'Display (embed) a lead/prospect questionnaire form on your website to collect leads that will integrate right into your Visto account.',
      tips: [
        'Display a questionnaire form on your website to collect prospect information.',
        'Turn leads into Visto clients and process their applications with 1-click.',
      ],
      allowedPlans: [PLAN_DISPLAY_NAME.GROWING, PLAN_DISPLAY_NAME.ESTABLISHED],
    },
    [FeatureBlockedType.CUSTOMIZE_EMAIL]: {
      title: 'Upgrade to Customize Emails',
      message:
        'Customize settings for automated emails sent to your clients (like notifications, reminders, etc).',
      tips: [
        'Personalize emails with your company logo.',
        'Change the from name and reply-to email address.',
      ],
      allowedPlans: [PLAN_DISPLAY_NAME.GROWING, PLAN_DISPLAY_NAME.ESTABLISHED],
    },
    [FeatureBlockedType.CUSTOMIZE_NOTIFICATIONS]: {
      title: 'Upgrade to Customize Notifications',
      message:
        'Customize the cadence of when email notification reminders are sent to your clients for pending tasks.',
    },
    [FeatureBlockedType.CUSTOMIZE_WELCOME]: {
      title: 'Upgrade to Customize Welcome Message',
      message:
        'Customize your clients portal with a welcome message from you and your team. This will show up on your client home page.',
      tips: [
        'Display a welcome message on your clients dashboard',
        'Display your company logo',
      ],
      allowedPlans: [PLAN_DISPLAY_NAME.GROWING, PLAN_DISPLAY_NAME.ESTABLISHED],
    },
    [FeatureBlockedType.MFA]: {
      title: 'Upgrade to Enable MFA',
      message:
        'Multi-factor authentication is the process of adding an additional step to your sign in process for Visto. In this case, we use a TOTP (time-based one-time password) additional verification flow.',
      allowedPlans: [PLAN_DISPLAY_NAME.ESTABLISHED],
    },
    [FeatureBlockedType.FORMS]: {
      title: 'Upgrade to Create and Edit Forms',
      message:
        'Create custom forms to collect information from your clients. These forms can be used to collect information for your applications.',
      tips: [
        'Build your own custom forms to collect information from your clients',
        'Assign the forms to your clients applications',
      ],
      allowedPlans: [PLAN_DISPLAY_NAME.GROWING, PLAN_DISPLAY_NAME.ESTABLISHED],
    },
    [FeatureBlockedType.TASKS]: {
      title: 'Upgrade to Create and Edit Tasks',
      message:
        'Create custom tasks to assign to your clients. This can include instructions on actions to take, uploading specific documents, and more!',
      tips: [
        'Build your own custom tasks with specific instructions for your client',
        'Assign the tasks to your clients applications',
      ],
      allowedPlans: [PLAN_DISPLAY_NAME.GROWING, PLAN_DISPLAY_NAME.ESTABLISHED],
    },
    [FeatureBlockedType.RETAINER]: {
      title: 'Signature Documents Limit Reached',
      message:
        'You have reached the maximum number of documents you can sign for the current month. Upgrade your account to unlock more signature documents.',
    },
    [FeatureBlockedType.USERS]: {
      title: 'Maximum Number of Users Reached',
      message:
        'You have reached the maximum number of users. Upgrade your account to unlock more users.',
    },
    [FeatureBlockedType.DRAFT]: {
      title: 'Upgrade to Draft Documents with AI',
      message:
        'Draft documents in seconds with Visto Copilot, send retainers for e-signing, and more!',
      tips: [
        'Automatically draft documents in seconds with Visto Copilot AI',
        'Draft documents like cover letters, retainer agreements, study plans and more!',
        'Auto-fill templates with dynamic variables from your clients profile',
        'Send retainers for e-signing',
      ],
      allowedPlans: [PLAN_DISPLAY_NAME.COPILOT_DRAFT],
    },
  };

  const content = get(CONTENT, type, null);

  const Content = (
    <div>
      <div className="bg-gradient-to-r from-[#2897f2] to-blue-600 rounded-full w-[70px] h-[70px] flex justify-center items-center mx-auto mb-6">
        <Lock className="text-white" size={30} />
      </div>
      <div className="max-w-[600px] mx-auto">
        <Text.Heading
          variant="none"
          className="fs24 text-center font-body font-bold mb-2"
        >
          {content?.title}
        </Text.Heading>
        <Text.Paragraph className="text-center mb-3">
          {content?.message}
        </Text.Paragraph>
        {(content?.tips || content?.allowedPlans) && (
          <div className="bg-gray-50 border border-gray-200 max-w-[400px] mx-auto rounded-md p-6 mt-6">
            {content?.tips && (
              <div>
                <Text.Paragraph className="text-center mb-1 font-semibold">
                  Features:
                </Text.Paragraph>
                <div className="space-y-3">
                  {content.tips.map((tip, i) => {
                    return (
                      <div
                        key={`feature-tip-${i}`}
                        className="flex items-center"
                      >
                        <VistoCheckCircle className="mr-3" />
                        <Text.Paragraph className="text-left fs14">
                          {tip}
                        </Text.Paragraph>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {content?.allowedPlans && (
              <div
                className={
                  content?.tips ? `mt-5 pt-3 border-t border-gray-300` : ''
                }
              >
                <Text.Paragraph className="text-center mb-1 font-semibold">
                  Available on the following plan(s):
                </Text.Paragraph>
                <Text.Paragraph className="text-center fs14">
                  {concatenateStrings(content.allowedPlans)}
                </Text.Paragraph>
              </div>
            )}
          </div>
        )}
      </div>
      <Button
        className="mx-auto mt-8"
        size="large"
        link={'/account/billing'}
        isPinging
      >
        Upgrade your Account
      </Button>
      <Text.Paragraph className="fs12 font-semibold px-2 py-1 rounded-md text-gray-600 text-center mt-2 opacity-60 hover:opacity-100">
        Have questions?{' '}
        <button
          className="font-semibold underline"
          onClick={() => {
            Crisp.chat.open();
          }}
        >
          Send us a message!
        </button>
      </Text.Paragraph>
    </div>
  );

  if (isOverlay) {
    return (
      <div
        className={`bg-black bg-opacity-60 absolute right-0 bottom-0 w-full h-full z-[9] flex justify-center items-center transition-all ${
          className ?? ''
        }`}
      >
        <div className="visto-component w-[80%] max-w-[750px]">{Content}</div>
      </div>
    );
  }

  return <div className={className ?? ''}>{Content}</div>;
};

export const FeaturedBlockedNoticeMini: FC<{
  type: FeatureBlockedType;
  isOverlay?: boolean;
  hideOverflow?: boolean;
  className?: string;
}> = ({ type, isOverlay = false, className }) => {
  const CONTENT: {
    [key in FeatureBlockedType]?: {
      title: string;
      message: string;
      tips?: string[];
      allowedPlans?: string[];
      cta?: {
        label: string;
        link: string;
      };
    };
  } = {
    [FeatureBlockedType.CHAT]: {
      title: 'Monthly Limit Reached',
      message:
        'Upgrade or wait for your billing period to reset to send more messages.',
      cta: {
        label: 'Upgrade Your Account',
        link: '/account/billing',
      },
    },
    [FeatureBlockedType.CHAT_TRIAL]: {
      title: 'Free Trial Limit Reached',
      message:
        'Upgrade your account to unlock more messages and continue using the chat feature.',
      cta: {
        label: 'Upgrade Your Account',
        link: '/account/billing',
      },
    },
    [FeatureBlockedType.CUSTOMIZE_LOGO]: {
      title: 'Upgrade to Customize Branding',
      message:
        'Brand your account with company logo that can be displayed on your clients portal navigation and automated emails.',
      cta: {
        label: 'Upgrade Your Account',
        link: '/account/billing',
      },
    },
  };

  const content = get(CONTENT, type, null);

  const Content = (
    <div>
      <div className="bg-gradient-to-r from-[#2897f2] to-blue-600 rounded-full w-[40px] h-[40px] flex justify-center items-center mx-auto mb-2">
        <Lock className="text-white" size={20} />
      </div>
      <Text.Heading
        variant="none"
        className="fs18 text-center font-body font-bold mb-0.5"
      >
        {content?.title}
      </Text.Heading>
      <Text.Paragraph className="text-center fs14">
        {content?.message}
      </Text.Paragraph>
      {content?.cta?.label && content?.cta?.link && (
        <div className="flex justify-center mt-3">
          <Link
            className="font-bold underline text-blue-600 hover:text-blue-800"
            href={content.cta.link}
          >
            {content.cta.label}
          </Link>
        </div>
      )}
    </div>
  );

  if (isOverlay) {
    return (
      <div
        className={`bg-black bg-opacity-60 absolute right-0 bottom-0 w-full h-full z-[9] flex justify-center items-center transition-all ${
          className ?? ''
        }`}
      >
        <div className="visto-component w-[80%] max-w-[750px]">{Content}</div>
      </div>
    );
  }

  return <div className={className ?? ''}>{Content}</div>;
};

export const FeaturedNoPermissionNotice: FC<{
  type: FeatureNoPermissionBlockedType;
  isOverlay?: boolean;
  hideOverflow?: boolean;
  className?: string;
}> = ({ type, isOverlay = false, hideOverflow = false, className }) => {
  useEffect(() => {
    if (!hideOverflow) {
      return;
    }

    const mainContent = document.getElementById('mainContentArea');

    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (!isOverlay || !hideOverflow) {
      return;
    }

    const mainContent = document.getElementById('mainContentArea');

    if (!mainContent) {
      return;
    }

    mainContent.style.overflow = 'hidden';

    return () => {
      mainContent.style.overflow = '';
    };
  }, []);

  const CONTENT: {
    [key in FeatureNoPermissionBlockedType]?: {
      title: string;
      message: string;
    };
  } = {
    [FeatureNoPermissionBlockedType.CREATE_APPLICATION]: {
      title: 'Missing Required Permissions',
      message:
        'You are missing the required permissions to create an application.',
    },
    [FeatureNoPermissionBlockedType.CREATE_DRAFT_TEMPLATE]: {
      title: 'Missing Required Permissions',
      message:
        'You are missing the required permissions to create and edit draft templates.',
    },
    [FeatureNoPermissionBlockedType.EXTRACT_TEXT_DOCUMENTS]: {
      title: 'Document Extraction Disabled',
      message:
        'Your as missing the required permissions to extract text (like names, date of birth, passport numbers, and more) from your clients supporting documents.',
    },
  };

  const content = get(CONTENT, type, null);

  const Content = (
    <div>
      <div className="bg-gradient-to-r from-[#2897f2] to-blue-600 rounded-full w-[70px] h-[70px] flex justify-center items-center mx-auto mb-6">
        <Lock className="text-white" size={30} />
      </div>
      <div className="max-w-[600px] mx-auto">
        <Text.Heading
          variant="none"
          className="fs24 text-center font-body font-bold mb-2"
        >
          {content?.title}
        </Text.Heading>
        <Text.Paragraph className="text-center mb-3">
          {content?.message}
        </Text.Paragraph>
        <Text.Paragraph className="text-center mb-3">
          Please contact your account administrator and request access.
        </Text.Paragraph>
      </div>
    </div>
  );

  if (isOverlay) {
    return (
      <div
        className={`bg-black bg-opacity-60 absolute right-0 bottom-0 w-full h-full z-[9] flex justify-center items-center transition-all ${
          className ?? ''
        }`}
      >
        <div className="visto-component w-[80%] max-w-[750px]">{Content}</div>
      </div>
    );
  }

  return <div className={className ?? ''}>{Content}</div>;
};

const concatenateStrings = (arr: string[]) => {
  if (arr.length === 0) {
    return '';
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr[0] + ' and ' + arr[1];
  } else {
    const lastItem = arr.pop();
    return arr.join(', ') + ', and ' + lastItem;
  }
};
